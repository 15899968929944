<template>
	<div>
		<pui-datatable :modelName="model" :showDeleteBtn="true" :modelColumnDefs="columnDefs"></pui-datatable>
		<!-- :actions="actions" -->
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
//import SatelliteParameterActions from './PmSatelliteParameterActions';
import { isSuperAdmin } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'PmSatelliteParameterGrid',
	data() {
		return {
			model: 'pmsatelliteparameter',
			/* actions: SatelliteParameterActions.actions, */
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				unitname: {
					createdCell: (td, cellData, rowData) => {
						return (td.innerHTML = '<label>' + cellData + (rowData.symbol ? ' (' + rowData.symbol + ')' : '') + '</label>');
					}
				}
			}
		};
	},
	methods: {},
	mounted() {
		if (!isSuperAdmin(this.session.profiles[0])) {
			this.externalFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
			};
		}
	}
};
</script>

<style lang="postcss" scoped></style>
